.Pill {
  display: inline-flex;
  align-items: center;
  padding: .5em 1em;
  border-radius: 5px;
  user-select: none;
  transition: all .1s ease;
}

.Pill.variant-highlighted {
  background-color: #115ffb;
  color: white;
}

.Pill.variant-plain {
  background-color: #e6e6e6;
  color: #212121;
}

.Pill.variant-ghost {
  background-color: transparent;
  border-width: 2px;
  border-style: dashed;
  border-color: #e6e6e6;
  color: #1e90ff;
}

.Pill.variant-ghost:hover {
  cursor: pointer;
}