@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap&subset=latin-ext);
* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  background-color: #f6f6f6 !important;
  overflow-y: auto !important;
}

#root {
  min-height: 100%;
}

.centerpiece-spinner {
  /* margin: 100px auto 0; */
  /* width: 70px; */
  text-align: center;
  /* border: 1px solid red; */
}

.centerpiece-spinner > div {
  width: 40px;
  height: 40px;
  margin: 0 3px;
  background-color: #ddd;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.centerpiece-spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.centerpiece-spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
    transform: scale(1.0);
  }
}

.box {
  position: relative;
}

.box-enter {
  opacity: 0;
}

.box-forward-entering {
  -webkit-animation-name: forward-entering;
          animation-name: forward-entering;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s
}

.box-forward-exiting {
  -webkit-animation-name: forward-exiting;
          animation-name: forward-exiting;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s
}

@-webkit-keyframes forward-entering {
  0% {left: 50%; top: 0px; opacity: 0;}
  100% {left: 0px; top: 0px; opacity: 1;}
}

@keyframes forward-entering {
  0% {left: 50%; top: 0px; opacity: 0;}
  100% {left: 0px; top: 0px; opacity: 1;}
}

@-webkit-keyframes forward-exiting {
  0% {left: 0px; top: 0px; opacity: 1;}
  100% {left: -50px; top: 0px; opacity: 0;}
}

@keyframes forward-exiting {
  0% {left: 0px; top: 0px; opacity: 1;}
  100% {left: -50px; top: 0px; opacity: 0;}
}

.box-backward-entering {
  -webkit-animation-name: backward-entering;
          animation-name: backward-entering;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s
}

.box-backward-exiting {
  -webkit-animation-name: backward-exiting;
          animation-name: backward-exiting;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s
}

@-webkit-keyframes backward-entering {
  0% {left: -50px; top: 0px; opacity: 0;}
  100% {left: 0px; top: 0px; opacity: 1;}
}

@keyframes backward-entering {
  0% {left: -50px; top: 0px; opacity: 0;}
  100% {left: 0px; top: 0px; opacity: 1;}
}

@-webkit-keyframes backward-exiting {
  0% {left: 0px; top: 0px; opacity: 1;}
  100% {left: 50%; top: 0px; opacity: 0;}
}

@keyframes backward-exiting {
  0% {left: 0px; top: 0px; opacity: 1;}
  100% {left: 50%; top: 0px; opacity: 0;}
}

.leftBox {

}

.leftBox-enter {
  opacity: 0;
}

.leftBox-exit {
  opacity: 0;
}

.leftBox-entering {
  -webkit-animation-name: entering;
          animation-name: entering;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s
}

.leftBox-exiting {
  -webkit-animation-name: forward-exiting;
          animation-name: forward-exiting;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s
}

@-webkit-keyframes entering {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes entering {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@-webkit-keyframes exiting {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes exiting {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

.ListItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 56px;
  padding: 0 16px;
  border-bottom: 1px solid #eee;
  min-width: 600px;
  cursor: pointer;
}

.ListItem:hover {
  background-color: #fcfcfc !important;
}

.selected:hover {
  background-color: #8fc8ff !important;
}

.ListItem:first-of-type {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.ListItem:last-of-type {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom: none;
}

.Pill {
  display: inline-flex;
  align-items: center;
  padding: .5em 1em;
  border-radius: 5px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: all .1s ease;
}

.Pill.variant-highlighted {
  background-color: #115ffb;
  color: white;
}

.Pill.variant-plain {
  background-color: #e6e6e6;
  color: #212121;
}

.Pill.variant-ghost {
  background-color: transparent;
  border-width: 2px;
  border-style: dashed;
  border-color: #e6e6e6;
  color: #1e90ff;
}

.Pill.variant-ghost:hover {
  cursor: pointer;
}
.nested-box {
  /* border: 1px solid red; */
}

.nested-box .nested-box {
  margin-left: 50px;
}
.content-line {
  display: flex;
  align-items: center;
  /* border: 1px solid blue; */
  background-color: #fff;
  padding: 10px;
  transition: background-color .1s ease;
}

.content-line:hover {
  background-color: #eee;
}
.content-line-text {
  flex: 1 1;
}
