.box {
  position: relative;
}

.box-enter {
  opacity: 0;
}

.box-forward-entering {
  animation-name: forward-entering;
  animation-duration: 0.2s
}

.box-forward-exiting {
  animation-name: forward-exiting;
  animation-duration: 0.2s
}

@keyframes forward-entering {
  0% {left: 50%; top: 0px; opacity: 0;}
  100% {left: 0px; top: 0px; opacity: 1;}
}

@keyframes forward-exiting {
  0% {left: 0px; top: 0px; opacity: 1;}
  100% {left: -50px; top: 0px; opacity: 0;}
}

.box-backward-entering {
  animation-name: backward-entering;
  animation-duration: 0.2s
}

.box-backward-exiting {
  animation-name: backward-exiting;
  animation-duration: 0.2s
}

@keyframes backward-entering {
  0% {left: -50px; top: 0px; opacity: 0;}
  100% {left: 0px; top: 0px; opacity: 1;}
}

@keyframes backward-exiting {
  0% {left: 0px; top: 0px; opacity: 1;}
  100% {left: 50%; top: 0px; opacity: 0;}
}

.leftBox {

}

.leftBox-enter {
  opacity: 0;
}

.leftBox-exit {
  opacity: 0;
}

.leftBox-entering {
  animation-name: entering;
  animation-duration: 0.2s
}

.leftBox-exiting {
  animation-name: forward-exiting;
  animation-duration: 0.2s
}

@keyframes entering {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes exiting {
  0% {opacity: 1;}
  100% {opacity: 0;}
}
