@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap&subset=latin-ext');
* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  background-color: #f6f6f6 !important;
  overflow-y: auto !important;
}

#root {
  min-height: 100%;
}
