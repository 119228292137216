.ListItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 56px;
  padding: 0 16px;
  border-bottom: 1px solid #eee;
  min-width: 600px;
  cursor: pointer;
}

.ListItem:hover {
  background-color: #fcfcfc !important;
}

.selected:hover {
  background-color: #8fc8ff !important;
}

.ListItem:first-of-type {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.ListItem:last-of-type {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom: none;
}
