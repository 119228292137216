.content-line {
  display: flex;
  align-items: center;
  /* border: 1px solid blue; */
  background-color: #fff;
  padding: 10px;
  transition: background-color .1s ease;
}

.content-line:hover {
  background-color: #eee;
}